.error {
	background-color: #d8011e;
	color: white;
	padding: 0.5em 1em;
}

.panelAddition {
	color: white;
	opacity: 0.7;
}

.panelAddition:hover {
	opacity: 1;
	cursor: pointer;
}

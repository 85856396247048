.window {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 2;
	padding: 4.5em 1em 1em 1em;
	user-select: none;
	overflow-y: scroll;
}

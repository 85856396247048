.panel {
	padding: 0.75em;
	background-color: white;
	border-bottom-left-radius: 0.5em;
	border-bottom-right-radius: 0.5em;
}

.task {
	border-bottom: 1px solid #ccc;
	margin-bottom: 0.5em;
}

.task:last-child {
	border-bottom: none;
	margin-bottom: 0;
}

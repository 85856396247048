.panel {
	background-color: white;
	padding: 0.75em;
	border-bottom-left-radius: 0.5em;
	border-bottom-right-radius: 0.5em;
}

.timerButton {
	text-align: center;
}

.timerButtonRed,
.timerButtonGreen {
	font-size: 3em;
	width: 300px;
	height: 300px;
	border-radius: 50%;
}

.timerButtonRed {
	background-color: red;
	color: white;
	opacity: 0.8;
}

.timerButtonRed:hover {
	background-color: red;
	opacity: 1;
	color: white !important;
}

.embed {
	padding-left: 1.1em;
}

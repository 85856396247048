.row {
	display: flex;
	line-height: 2em;
	justify-content: space-between;
	column-gap: 1px;
}

.row > div {
	flex-basis: 25%;
	flex-grow: 0;
	flex-shrink: 0;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 0 0.5em;
}

.row > div:first-child {
	padding-left: 1em;
}

.alt {
	background-color: #e2e1de;
}

.row:hover {
	background-color: #bfd1e7;
	cursor: pointer;
}

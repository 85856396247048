.panel {
	background-color: white;
	border-bottom-left-radius: 0.5em;
	border-bottom-right-radius: 0.5em;
	padding: 0 0.75em 0.5em 0.75em;
	overflow: hidden;
}

.list {
	background-color: #e2e2e2;
	border: 1px solid #5d5d5c;
	border-radius: 3px;
}

.list > li {
	padding: 0 0.5em;
	line-height: 2em;
}

.list > li:first-child {
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
}

.list > li:last-child {
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
}

.list > li:nth-child(odd) {
	background-color: #f6f6f6;
}

.list > li:hover {
	background-color: #bfd1e7;
	cursor: pointer;
}

.selected {
	background-color: #bfd1e7 !important;
}

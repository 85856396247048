.flight {
	width: 12px;
	height: 12px;
	border-radius: 50%;
}

.ok {
	background-color: yellowgreen;
}

.failed {
	background-color: red;
}

.notRated {
	background-color: #999;
}

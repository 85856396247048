.flightList {
	display: flex;
	column-gap: 4px;
	padding-bottom: 0.5em;
	padding-top: 0.5em;
}

.task {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.header {
	display: flex;
	justify-content: space-between;
	column-gap: 1px;
	line-height: 2em;
	background-color: #bfd1e7;
}

.header > div {
	flex-basis: 25%;
	flex-grow: 0;
	flex-shrink: 0;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	border-right: 1px solid white;
	padding: 0 0.5em;
	display: flex;
	justify-content: space-between;
	align-items: center;
	column-gap: 4px;
	cursor: pointer;
}

.header > div:first-child {
	padding-left: 1em;
}

.header > div:last-child {
	border-right: none;
	padding-right: 1em;
}

.header svg {
	fill: #535557;
}

.disabled svg {
	opacity: 0.3;
}

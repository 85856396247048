*,
html {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	height: 100%;
}

body {
	font-family: "Open Sans", sans-serif;
	font-weight: 400;
	font-size: 16px;
	line-height: 1.3em;
	color: #535557;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	width: 100%;
	height: 100%;
	padding: 78px 1em 1em 1em;
	position: relative;
	background: rgb(229, 236, 247);
	background: linear-gradient(0deg, rgba(229, 236, 247, 1) 0%, rgba(92, 135, 254, 1) 100%);
}

a {
	color: #d8011e;
	text-decoration: none;
	font-weight: 600;
}

a:hover {
	text-decoration: underline;
}

textarea,
input,
select {
	width: 100%;
	font-family: "Open Sans", sans-serif;
	font-weight: 400;
	font-size: 16px;
	color: #333;
	line-height: 1em;
	vertical-align: middle;
	border: 1px solid #999;
	border-radius: 5px;
}

h4 {
	font-weight: 600;
	color: #535557;
	font-size: 1em;
}

textarea,
input {
	padding: 0.25em 0.5em;
}

select {
	padding: 4px 8px;
	border-radius: 8px;
}

input[type="checkbox"],
input[type="radio"] {
	width: auto;
}

textarea {
	line-height: 1.3em;
	resize: none;
}

::-webkit-input-placeholder {
	color: #aaa;
}

:-ms-input-placeholder {
	color: #aaa;
}

::placeholder {
	color: #aaa;
}

button,
input[type="submit"] {
	font-size: 1em;
	background-color: #c1d14f;
	border: none;
	color: #535557;
	padding: 0 12px;
	line-height: 2em;
	text-decoration: none;
	cursor: pointer;
	border-radius: 5px;
	outline: none;
}

button:not(:disabled):hover {
	color: #000;
}

button:disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

button.add {
	background-color: #090;
	padding: 0;
	width: 21px;
	height: 21px;
	border-radius: 50%;
	line-height: 21px;
	font-size: 21px;
}

button.add:hover {
	transform: scale(1.2);
}

label.fieldlabel > span {
	line-height: 1.2em;
	font-size: 0.8em;
	font-weight: 600;
}

.required > span {
	background-color: #90ee90;
	padding: 0 4px;
	border-radius: 4px;
}

span.small {
	font-size: 0.8em;
}

strong {
	font-weight: 600;
}

div.panel {
	margin: 0 auto;
}

div.panel.small {
	max-width: 480px;
}

div.panel.medium {
	max-width: 640px;
}

div.panel.large {
	max-width: 1000px;
}

div.panel.full {
	height: calc(100vh - 78px - 1em);
	position: relative;
}

div.panel > .panelBottom {
	background-color: white;
	border-bottom-left-radius: 0.5em;
	border-bottom-right-radius: 0.5em;
}

.panelPadding {
	padding: 1em;
}

form.login {
	padding: 1em;
}

form.login label {
	display: flex;
	align-items: center;
	margin-bottom: 1em;
}

form.login label span {
	display: block;
	width: 20%;
}

form.login label input {
	width: 80%;
}

form.login p {
	text-align: right;
	margin-top: 1em;
}

div.panelHead {
	background-color: #5d5d5c;
	padding: 0 0.75rem;
	border-top-left-radius: 0.5rem;
	border-top-right-radius: 0.5rem;
	color: #c1d14f;
	height: 36px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

div.panelHead div.title {
	font-weight: 600;
	font-size: 1.2em;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

div.panelHead div.nav {
	display: flex;
	justify-content: flex-end;
	column-gap: 1em;
}

div.spinner {
	position: fixed;
	z-index: 100;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	padding: 0 2em;
	background-color: rgba(0, 0, 0, 0.4);
	overflow-y: scroll;
}

span.click {
	color: #d8011e;
	font-weight: 600;
}

span.click:hover {
	cursor: pointer;
	text-decoration: underline;
}

.sk-circle {
	margin: 100px auto;
	width: 40px;
	height: 40px;
	position: relative;
}
.sk-circle .sk-child {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
}
.sk-circle .sk-child::before {
	content: "";
	display: block;
	margin: 0 auto;
	width: 15%;
	height: 15%;
	background-color: white;
	border-radius: 100%;
	-webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
	animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}
.sk-circle .sk-circle2 {
	-webkit-transform: rotate(30deg);
	-ms-transform: rotate(30deg);
	transform: rotate(30deg);
}
.sk-circle .sk-circle3 {
	-webkit-transform: rotate(60deg);
	-ms-transform: rotate(60deg);
	transform: rotate(60deg);
}
.sk-circle .sk-circle4 {
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}
.sk-circle .sk-circle5 {
	-webkit-transform: rotate(120deg);
	-ms-transform: rotate(120deg);
	transform: rotate(120deg);
}
.sk-circle .sk-circle6 {
	-webkit-transform: rotate(150deg);
	-ms-transform: rotate(150deg);
	transform: rotate(150deg);
}
.sk-circle .sk-circle7 {
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}
.sk-circle .sk-circle8 {
	-webkit-transform: rotate(210deg);
	-ms-transform: rotate(210deg);
	transform: rotate(210deg);
}
.sk-circle .sk-circle9 {
	-webkit-transform: rotate(240deg);
	-ms-transform: rotate(240deg);
	transform: rotate(240deg);
}
.sk-circle .sk-circle10 {
	-webkit-transform: rotate(270deg);
	-ms-transform: rotate(270deg);
	transform: rotate(270deg);
}
.sk-circle .sk-circle11 {
	-webkit-transform: rotate(300deg);
	-ms-transform: rotate(300deg);
	transform: rotate(300deg);
}
.sk-circle .sk-circle12 {
	-webkit-transform: rotate(330deg);
	-ms-transform: rotate(330deg);
	transform: rotate(330deg);
}
.sk-circle .sk-circle2::before {
	-webkit-animation-delay: -1.1s;
	animation-delay: -1.1s;
}
.sk-circle .sk-circle3::before {
	-webkit-animation-delay: -1s;
	animation-delay: -1s;
}
.sk-circle .sk-circle4::before {
	-webkit-animation-delay: -0.9s;
	animation-delay: -0.9s;
}
.sk-circle .sk-circle5::before {
	-webkit-animation-delay: -0.8s;
	animation-delay: -0.8s;
}
.sk-circle .sk-circle6::before {
	-webkit-animation-delay: -0.7s;
	animation-delay: -0.7s;
}
.sk-circle .sk-circle7::before {
	-webkit-animation-delay: -0.6s;
	animation-delay: -0.6s;
}
.sk-circle .sk-circle8::before {
	-webkit-animation-delay: -0.5s;
	animation-delay: -0.5s;
}
.sk-circle .sk-circle9::before {
	-webkit-animation-delay: -0.4s;
	animation-delay: -0.4s;
}
.sk-circle .sk-circle10::before {
	-webkit-animation-delay: -0.3s;
	animation-delay: -0.3s;
}
.sk-circle .sk-circle11::before {
	-webkit-animation-delay: -0.2s;
	animation-delay: -0.2s;
}
.sk-circle .sk-circle12::before {
	-webkit-animation-delay: -0.1s;
	animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleBounceDelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@keyframes sk-circleBounceDelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

div.error,
div.success,
div.tempError > div,
div.tempSuccess > div {
	font-weight: 600;
	background-color: #090;
	color: white;
	padding: 0.5em 1em;
}

div.tempError,
div.tempSuccess {
	position: fixed;
	top: -0;
	transform: translateY(-100%);
	left: 50%;
	width: 300px;
	margin-left: -150px;
	text-align: center;
	z-index: 10;
	transition: transform 0.25s ease-in-out;
}

div.tempSuccess.show,
div.tempError.show {
	transform: translateY(0);
}

div.tempError > div,
div.tempSuccess > div {
	border-bottom-left-radius: 0.5em;
	border-bottom-right-radius: 0.5em;
}

div.tempError > div,
div.error {
	background-color: #d8011e;
}

form.login div.error {
	padding: 1em;
}

div.close {
	position: absolute;
	left: -16px;
	top: -16px;
	width: 32px;
	height: 32px;
	border-radius: 50%;
	background-color: #d8011e;
	color: white;
	text-align: center;
	line-height: 32px;
	cursor: pointer;
	font-size: 1.5em;
	transition: all 0.2s ease-in-out;
	z-index: 1;
}

div.close:hover {
	transform: scale(1.2);
}

label.checkbox {
	line-height: 1.5em;
	padding-top: 0.75em;
	display: block;
	font-size: 0.9em;
	white-space: nowrap;
}

label.radio {
	display: flex;
	align-items: center;
	column-gap: 4px;
	margin: 0.5em 0;
}

@keyframes ldio-dyw8t9yacb-o {
	0% {
		opacity: 1;
		transform: translate(0 0);
	}
	49.99% {
		opacity: 1;
		transform: translate(44px, 0);
	}
	50% {
		opacity: 0;
		transform: translate(44px, 0);
	}
	100% {
		opacity: 0;
		transform: translate(0, 0);
	}
}
@keyframes ldio-dyw8t9yacb {
	0% {
		transform: translate(0, 0);
	}
	50% {
		transform: translate(44px, 0);
	}
	100% {
		transform: translate(0, 0);
	}
}
.ldio-dyw8t9yacb div {
	position: absolute;
	width: 44px;
	height: 44px;
	border-radius: 50%;
	top: 28px;
	left: 6px;
}
.ldio-dyw8t9yacb div:nth-child(1) {
	background: #d8011e;
	animation: ldio-dyw8t9yacb 7.142857142857142s linear infinite;
	animation-delay: -3.571428571428571s;
}
.ldio-dyw8t9yacb div:nth-child(2) {
	background: #01e53e;
	animation: ldio-dyw8t9yacb 7.142857142857142s linear infinite;
	animation-delay: 0s;
}
.ldio-dyw8t9yacb div:nth-child(3) {
	background: #d8011e;
	animation: ldio-dyw8t9yacb-o 7.142857142857142s linear infinite;
	animation-delay: -3.571428571428571s;
}
.loadingio-spinner-dual-ball-o20bp2k35w {
	width: 32px;
	height: 32px;
	display: inline-block;
	overflow: hidden;
	vertical-align: middle;
}
.ldio-dyw8t9yacb {
	width: 100%;
	height: 100%;
	position: relative;
	transform: translateZ(0) scale(0.32);
	backface-visibility: hidden;
	transform-origin: 0 0; /* see note above */
}
.ldio-dyw8t9yacb div {
	box-sizing: content-box;
}

div.header {
	padding: 0 1em;
	background-color: #535557;
	color: white;
	line-height: 2.5em;
	display: flex;
	justify-content: space-between;
	align-items: center;
	column-gap: 1em;
}

ul.selectList div.header {
	border-top-left-radius: 5px;
}

ul {
	list-style: none;
}

ul.selectList div.title {
	padding: 0.25em 0.5em;
	display: flex;
	justify-content: space-between;
	position: relative;
}

ul.selectList div.symbols {
	display: flex;
	justify-content: space-between;
	column-gap: 4px;
}

div.plans {
	padding-left: 6px;
	padding-bottom: 6px;
}

li.noContent {
	padding: 0.25em 0.5em;
	font-style: italic;
	font-size: 0.9em;
}

div.teaserButtons {
	display: flex;
	flex-wrap: wrap;
	gap: 1em;
	padding: 1em;
}

div.buttons {
	padding-top: 1em;
	display: flex;
	justify-content: flex-end;
	column-gap: 1em;
}

div.windowBackground {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 2;
	padding-top: 3em;
	user-select: none;
}

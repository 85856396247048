.panelContent {
	padding: 0.75em;
	background-color: white;
	border-bottom-left-radius: 0.5em;
	border-bottom-right-radius: 0.5em;
}

.twoCols {
	display: flex;
	justify-content: space-between;
	column-gap: 1em;
}

.fieldLabel {
	display: block;
	width: 100%;
	margin-bottom: 1em;
}

.fieldLabel > span {
	font-size: 0.85em;
	line-height: 1em;
}

.radioLabel {
	display: block;
	width: 100%;
	cursor: pointer;
	display: flex;
	align-items: center;
}

.radioLabel input {
	margin-right: 4px;
}

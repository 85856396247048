.logo {
	position: absolute;
	left: 1em;
	top: 8px;
}

.logo svg {
	width: 90px;
	cursor: pointer;
}

.error {
	padding: 1em;
	background-color: white;
}

.error > div {
	padding: 1em;
	background-color: #f7ccd2;
	border: 1px solid #d8011e;
	border-radius: 5px;
	display: flex;
	column-gap: 2em;
}

.error svg {
	width: 56px;
	height: 53px;
	fill: white;
}

.ask {
	position: fixed;
	z-index: 5;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	padding: 0;
	background-color: rgba(0, 0, 0, 0.4);
	overflow-y: scroll;
}

.ask > div {
	margin: 8em auto 0 auto;
	max-width: 680px;
	background-color: white;
	border-radius: 5px;
	padding: 3.5em 3.5em 2.5em 3.5em;
}

.content {
	display: flex;
	column-gap: 4em;
}

.ask svg {
	width: 95px;
	height: auto;
}

.ask h2 {
	font-size: 1.25em;
	margin-bottom: 1em;
}

.buttons {
	display: flex;
	justify-content: flex-end;
	column-gap: 1em;
	margin-top: 2em;
}

.teaserButton {
	width: 200px;
	height: 200px;
	background-color: #e9e9e9;
	border-radius: 0.5em;
	position: relative;
	padding: 1em;
	border: 3px solid #c1d14f;
	border-bottom-width: 2em;
}

.teaserButton:hover {
	cursor: pointer;
}

.teaserButton div {
	position: absolute;
	left: 0;
	bottom: -2em;
	right: 0;
	text-align: center;
	font-weight: 600;
	color: #5d5d5c;
	line-height: 2em;
	user-select: none;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.teaserButton svg {
	max-width: 100%;
	max-height: 100%;
	display: block;
	margin: auto;
	opacity: 0.5;
	transition: opacity 0.25s ease-in-out;
}

.teaserButton:hover svg {
	opacity: 1;
}

.content {
	background-color: white;
	padding: 1px;
	border-bottom-left-radius: 0.5em;
	border-bottom-right-radius: 0.5em;
	position: absolute;
	left: 0;
	width: 100%;
	bottom: 0;
	top: 36px;
	overflow: hidden;
}

.list {
	position: absolute;
	top: 82px;
	right: 1px;
	bottom: 1px;
	left: 1px;
	background-color: #f2f2f2;
}

.search {
	padding: 0 0.75em;
	display: flex;
	justify-content: space-between;
	column-gap: 1em;
	align-items: center;
}

.search > div {
	flex-basis: 70%;
	flex-grow: 1;
	flex-shrink: 1;
}

.search button {
	white-space: nowrap;
}

.panel {
	padding: 0.75em;
	background-color: white;
	border-bottom-left-radius: 0.5em;
	border-bottom-right-radius: 0.5em;
}

.panel label {
	display: block;
	padding-bottom: 1em;
}

.panel p {
	text-align: right;
}

#topBar {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	height: 60px;
	background-color: white;
	z-index: 2;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
}

#topBar:hover {
	z-index: 6;
}

#topBar div.development {
	position: absolute;
	left: -36px;
	top: -36px;
	width: 72px;
	height: 72px;
	background-color: #535557;
	color: yellow;
	font-weight: 600;
	font-size: 0.9rem;
	text-align: center;
	padding-top: 52px;
	z-index: 1;
	transform: rotate(-45deg);
}

#menu {
	position: absolute;
	font-size: 1em;
	right: 0.75em;
	bottom: 0;
	z-index: 2;
	line-height: 2em;
	list-style: none;
}

#menu > li > span,
#menu > li > a {
	text-transform: uppercase;
}

#menu a {
	color: #535557;
	font-weight: 400;
	display: block;
}

#menu svg {
	width: 14px;
	margin: 0 0.5em;
	cursor: pointer;
	fill: #5d5d5c;
}

#menu svg:hover {
	fill: #c1d14f;
}

#menu span:hover,
#menu a:hover {
	color: #c1d14f;
	text-decoration: none;
	cursor: pointer;
}

#menu li {
	white-space: nowrap;
}

#menu li > span,
#menu li > a {
	padding: 0 0.5em;
}

#menu li > a.selected {
	font-weight: 600;
}

#menu > li {
	display: inline-block;
	position: relative;
}
